import '../styles/index.scss'


function is_touch_device() {
  var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  var mq = function(query) {
    return window.matchMedia(query).matches
  }

  if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
    return true
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
  return mq(query)
}

jQuery(function ($) {
  const desktopBreakpoint = 800 
  let lastWidth
  const slickOpts = {
    arrows: false,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [{
      breakpoint: desktopBreakpoint,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }]
  }
  const slideCount = $('.slider .s').length
  function stopAllSound () {
    $('video').prop('muted', true)
    $('a.speaker').removeClass('on')
  }
  const doSlides = function (evt, slick) {
    $('.slider .s').each(function () {
      if ($(this).attr('data-image')) {
        const url = $(this).attr('data-image')
        if (/\.mp4/.test(url)) {
          $(this).find('video').remove()
          $(this).find('a.speaker').remove()

          const v = $('<video>').prop({
            width: $(this).width(),
            height: $(this).height(),
            src: url,
            autoplay: true,
            playsInline: true,
            muted: true,
            loop: true,
            controls: false
          })
          v.css({
            objectFit: 'cover',
            position: 'absolute',
            left: 0,
            top: 0
          })
          $(this).css({ 
            position: 'relative',
            overflow: 'hidden'
          }).append(v)
          var speaker = $('<a>').addClass('speaker').click(function (evt) {
            var hadOn = $(this).hasClass('on')
            stopAllSound()
            if (!hadOn) {
              v.prop('muted', false)
              $(this).addClass('on')
            }
            evt.preventDefault()
            return false
          })
          $(this).append(speaker)

        } else {
          $(this).css({
            backgroundImage: 'url(' + $(this).attr('data-image') + ')'
          })
        }
      }
    })
    $('.slider').css({
      backgroundImage: 'url(' + $('.s.title').attr('data-image') + ')'
    })
    const w = $(window).width()
    if ($('.slider').slick && w !== lastWidth) {
      lastWidth = w
      // to mobile
      if (w <= desktopBreakpoint) {
        setTimeout(() => {
          $('.slider').slick('slickAdd', `<div><div class='s itinerary'>` + $('.desktop-title .itinerary').html() + `</div></div>`, 0)
          $('body').addClass('small').removeClass('big')
        }, 0)
        
      // to desktop
      } else {
        if ($('body').hasClass('small')) {
          $('.slider').slick('slickRemove', 1)
        }
        $('body').removeClass('not-first').removeClass('small').addClass('big')
      }
    }
  }
  let currSlide
  const beforeChange = (event, slick, currentSlide, nextSlide) => {
    stopAllSound()
    currSlide = nextSlide
    const slideThatMatters = $('body').hasClass('small') ? 1 : 0
    if (nextSlide > slideThatMatters) {
      $('.commentary').show()
      $('.itinerary').hide()
    } else {
      $('.commentary').hide()
      $('.itinerary').show()
    }
    if (nextSlide === 0) {
      $('body').removeClass('not-first')
    } else {
      $('body').addClass('not-first')
    }
    if (nextSlide === 2) {
      $($('body').is('.small') ? '.tap-to' : '.click-to').fadeOut(5000)
    }
  }
  $('.slider')
    .on('init', doSlides)
    .on('breakpoint', doSlides)
    .on('beforeChange', beforeChange)
    .slick(slickOpts)

  $('.desktop-title').prepend($('.s.title').html())
    // .find('.itinerary > div').append("<p class='click-to'>CLICK TO READ CURATOR'S COMMENTARY</p>")

  let intro = false
  function toggleIntro () {
    const slideThatMatters = $('body').hasClass('small') ? slideCount : slideCount - 2
    if (slideThatMatters === currSlide) return true

    $('#intro').toggle()
    intro = !intro
    if ($(this).is('.close')) return false
  }
  $('body').on('click', '.wrap,.close', toggleIntro)
  if (!is_touch_device()) {
    $('body').addClass('no-touch')
    let _going = false
    $(window).on('wheel', function (evt) {

      const $desktopTitle = $(evt.target).closest('.desktop-title')
      if ($desktopTitle[0]) {
        const $it = $desktopTitle.find('.itinerary')
        if ($it.find('div').outerHeight() - $it.height() + $it.offset().top > 2) return
      }
      if (intro) return
      if (!_going) {
        // backward
        if (evt.originalEvent.deltaY < 0) {
          $('.slider').slick('slickPrev')

          // forward
        } else {
          $('.slider').slick('slickNext')
        }
      }
      clearTimeout(_going)
      _going = setTimeout(function () {
        _going = false
      }, 40)
    })
  } else {
    $('body').addClass('touch')
  }
})
